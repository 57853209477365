import 'core-js/web/';
import './polyfills';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/de';
import { render } from 'react-dom';
import 'sanitize.css';
import './index.css';
import { SmexnetLazyLoader } from './SmexnetLazyLoader';
import { initNewRelicLogging } from 'src/utils/initNewRelicLogging';
import { initSentryLogging } from 'src/utils/initSentryLogging';

initSentryLogging();
initNewRelicLogging();
render(<SmexnetLazyLoader />, document.getElementById('root'));
