import { hexToRgba } from '../../../utils/colorHelpers';
import { sharedColors } from './sharedColors';
import { type CompanyConfigThemeColors } from './types';

const colors: CompanyConfigThemeColors = {
  ...sharedColors,

  /* Color definitions can be found here:
   * https://confluence.dev.eon.com/display/SMEXNET/Farbmatrix
   */

  // Primary colors
  colorPrimary0: '#0C2454',
  colorPrimary1: '#143C8C',
  colorPrimary2: '#0050A6',
  colorPrimary3: '#0771E3',
  colorPrimary4: '#80A7D2',
  colorPrimary5: '#E5EDF6',
  colorPrimary5Overlay: hexToRgba('#E5EDF6', 0.8),
  colorPrimary6: '#CCDCED',

  // Accents colors
  colorAccentsError: '#E63121',
  colorAccentsWarning: '#FFE100',
  colorAccentsSuccess: '#6BB52B',
  colorAccentsHighlight: '#FFE100',

  // Text colors
  colorTextPrimaryHighlight: '#0050A6', // colorPrimary2
  colorTextError: '#E63121', // colorAccentsError

  // Error colors
  colorError: '#8F0035',
  colorErrorHover: '#B80044',
  colorErrorActive: '#E60055',
  colorErrorDisabled: '#F280AA',
  colorErrorBackground: '#FCE6EE',

  // Warning colors
  colorWarning: '#EDBE00',
  colorWarningBackground: '#FDF8E5',

  // Success colors
  colorSuccess: '#64B42D',
  colorSuccessBackground: '#E1F5D3',

  // Avatar Highlight colors
  colorBackgroundAvatarHighlight: '#F59B00', // colorAccentsHighlight
  colorTextAvatarHighlight: '#FFFFFF', // colorTextSecondary
};

export default colors;
