import { CompanyTag } from 'src/application/theming/companyConfigs/types';
import { type Configuration } from 'src/config/type';

const testConfig: Configuration = {
  authentication: {
    isEnabled: false,
  },
  smartExtranetApi: {
    apiToken: '',
    baseUrl: '',
    mockedEndpoints: ['getAllCompanies'],
  },
  smartExtranetPublicApi: {
    baseUrl: '',
    mockedEndpoints: [],
  },
  googleMapsJavascriptApi: {
    authentication: {
      key: 'AIzaSyAAzZznav5yPrcQFBRrxOVj4QdQNSo4z6o',
      v: '3.42',
    },
  },
  recaptchaSiteKey: '',
  companyTag: CompanyTag.SHNG,
  serviceDeskUrl:
    'https://sigmatest.service-now.com/smart_extranet?id=support_request&sys_id=9585668c1b55d1104af732649b4bcba8',
  sentryLogging: {
    enabled: false,
  },
};

export default testConfig;
