import { CompanyTag } from 'src/application/theming/companyConfigs/types';
import { AuthProvider, type Configuration } from 'src/config/type';

export const pocAppConfig: Configuration = {
  authentication: {
    isEnabled: true,
    defaultProvider: AuthProvider.CIAM,
    providers: [
      {
        name: AuthProvider.CIAM,
        authBaseUrl: 'https://account-dev.${DOMAIN}/services/oauth2/authorize',
        tokenBaseUrl: 'https://account-dev.${DOMAIN}/services/oauth2/token',
        logoutBaseUrl:
          'https://account-dev.${DOMAIN}/secur/logout.jsp?retUrl=/apex/CIAM_SmartExtranetLogout?rls=0',
        clientId:
          '3MVG9sSN_PMn8tjSodyD28jSJp6PZvUoQEo2kfPVoYOxjleYaCFHo2BfzkutBlui8.ER4gWtNzYD4MJc9vQFL',
        mfaRegisterUrl: 'https://account-dev.${DOMAIN}/s/2fa-registration?appId=${APP_ID}',
        redirectUrl: 'https://dev-smexnet.${DOMAIN}/oauth/return/default',
        scope: 'openid profile refresh_token',
        refreshTokenLifetimeMilliseconds: 86_100_000,
      },
      {
        name: AuthProvider.AAD,
        authBaseUrl:
          'https://login.microsoftonline.com/b914a242-e718-443b-a47c-6b4c649d8c0a/oauth2/v2.0/authorize',
        tokenBaseUrl:
          'https://login.microsoftonline.com/b914a242-e718-443b-a47c-6b4c649d8c0a/oauth2/v2.0/token',
        logoutBaseUrl: '/',
        clientId: '4187c5cc-24c8-4ea0-bbf9-ebceac48a9d6',
        redirectUrl: 'https://dev-smexnet.${DOMAIN}/oauth/return/default',
        scope: 'openid profile email User.Read',
        refreshTokenLifetimeMilliseconds: 86_100_000,
      },
    ],
  },
  smartExtranetApi: {
    apiToken: 'USE_OPEN_ID_CONNECT_ACCESS_TOKEN',
    baseUrl: 'https://smex-api-dev.eon.com/api/${COMPANY_TAG}',
    mockedEndpoints: [],
  },
  maintenanceEnabled: false,
  maintenanceExcludedCompanies: [],
  companyTag: CompanyTag.SHNG,
  smartExtranetPublicApi: {
    baseUrl: 'https://smex-api-dev.eon.com/public/${COMPANY_TAG}',
    mockedEndpoints: [],
  },
  googleMapsJavascriptApi: {
    authentication: {
      key: 'AIzaSyAAzZznav5yPrcQFBRrxOVj4QdQNSo4z6o',
      v: '3.42',
    },
  },
  azureApplicationInsights: {
    instrumentationKey: '987c74ea-a83c-48f5-9db3-0c83e50e04a9',
  },
  recaptchaSiteKey: '6Lfn1EQfAAAAAEcyZP2VJM9ocLzmvHoPJA25SdqI',
  serviceDeskUrl:
    'https://sigmatest.service-now.com/smart_extranet?id=support_request&sys_id=9585668c1b55d1104af732649b4bcba8',
  sentryLogging: {
    enabled: true,
    dsn: 'https://759f2fb6ad655ee5ff1ea129095d54c0@sentry.eon.com/148',
  },
};
