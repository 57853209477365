import { CompanyTag } from 'src/application/theming/companyConfigs/types';

export const companyTagToAppId: Record<CompanyTag, string> = {
  [CompanyTag.AVA]: '601016',
  [CompanyTag.BAGE]: '601018',
  [CompanyTag.BAGN]: '601019',
  [CompanyTag.EDIS]: '601012',
  [CompanyTag.EENG]: '601013',
  [CompanyTag.EVNG]: '601041',
  [CompanyTag.ENG]: 'UNDEFINED',
  [CompanyTag.HANG]: '601011',
  [CompanyTag.HAW]: '601015',
  [CompanyTag.LEW]: '601015',
  [CompanyTag.LVN]: '601031',
  [CompanyTag.REGN]: '601040',
  [CompanyTag.RWW]: '601038',
  [CompanyTag.SHNG]: '601010',
  [CompanyTag.TNNG]: '601014',
  [CompanyTag.VSE]: 'UNDEFINED',
  [CompanyTag.WCON]: '601039',
  [CompanyTag.WEVG]: '601017',
  [CompanyTag.WN]: '601021',
  [CompanyTag.WENS]: '601042',
};
